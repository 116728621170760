import React from "react";
import classNames from "classnames/bind";
import styles from "./NavBar.module.css";
import logo from "../../assets/logo.svg";
import Button from "../../components/Button/Button";
import Chip from "../../components/Chip/Chip";

const cx = classNames.bind(styles);

const NavBar = ({ className, toggleLang }) => {
  const scrollToId = (location) => {
    const element = document.getElementById(location);
    window.scrollTo({
      behavior: element ? "smooth" : "auto",
      top: element ? element.offsetTop : 0,
    });
  };
  return (
    <nav className={cx("base", className)}>
      <img src={logo} alt="" />
      <div className={cx("links")}>
        <span className={cx("NavLink")} onClick={() => scrollToId("impact")}>
          Wat is IMPACT?
        </span>
        <span
          className={cx("NavLink")}
          onClick={() =>
            (window.location = "https://forms.gle/GYeSWWkLDrkwFkYw8")
          }
        >
          Hoodies <Chip>New</Chip>
        </span>
        <span className={cx("NavLink")} onClick={() => scrollToId("contact")}>
          Contact
        </span>
        <span className={cx("NavLink")}>
          <a href="/stichting">Stichting</a>
        </span>
        <Button big dark onClick={() => scrollToId("about")}>
          IMPACT 2024
        </Button>
        <Button big href="https://gofund.me/20244bd5">
          Doneren
        </Button>
        <span className={cx("NavLink")} onClick={toggleLang}>
          🇬🇧
        </span>
      </div>
    </nav>
  );
};

export default NavBar;
