import React from "react";
import classNames from "classnames/bind";
import styles from "./Chip.module.css";

const cx = classNames.bind(styles);

const Chip = ({ className, primary, outline, big, children, red }) => {
  return (
    <span
      className={cx("base", className, {
        primary,
        outline,
        big,
        red,
      })}
    >
      {children}
    </span>
  );
};

export default Chip;
