import React from "react";
import classNames from "classnames/bind";
import styles from "./Button.module.css";

const cx = classNames.bind(styles);

const Button = ({
  className,
  link,
  primary,
  outline,
  dark,
  big,
  bright,
  onClick,
  children,
  red,
  href,
}) => {
  return href ? (
    <a
      href={href}
      target="_blank"
      className={cx("base", className, {
        link,
        primary,
        outline,
        dark,
        big,
        bright,
        red,
        disabled: !onClick && !href,
      })}
    >
      {children}
    </a>
  ) : (
    <div
      className={cx("base", className, {
        link,
        primary,
        outline,
        dark,
        big,
        bright,
        red,
        disabled: !onClick,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Button;
