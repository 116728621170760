import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import Stichting from './Stichting';

const App = () => {
  return (
    <Router>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stichting" element={<Stichting />} />
        </Routes>

    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
