import React from "react";
import classNames from "classnames/bind";
import styles from "./WhatIsImpact.module.css";
import heartbeat from "../../assets/heartbeat.png";

const cx = classNames.bind(styles);

const WhatIsImpact = (props) => {
  return (
    <div id={"impact"} className={cx("base")}>
      <div className={cx("container")}>
        <h1>Wat is Impact?</h1>
        <p>
          IMPACT staat voor 'Inspiring Members to Proclaim the Advent of Christ
          Together'. Vertaald, het 'inspireren van (kerk)leden om de
          (weder)komst van Christus samen te verkondigen'.{" "}
        </p>

        <img src={heartbeat} alt="" />

        <p>
          Impact is een jongerenorganisatie die zich richt op het motiveren van
          jongeren en kerkleden om actief deel te nemen aan het evangeliseren.
          Dit wordt voornamelijk bereikt door het organiseren van een jaarlijkse
          mission trip in Nederland, waar Impact samenwerkt met een lokale kerk
          in een bepaalde stands. Er zijn verschillende outreach-activiteiten
          tijdens deze mission trip, evenals Bijbelstudie, workshops, spelletjes
          en socialisatie.
        </p>
        <p>
          Dit jaar wordt de derde keer dat Impact in Nederland georganiseerd
          wordt. Volg ons op{" "}
          <a href={"https://www.instagram.com/nederlandimpact/"}>Instagram</a>{" "}
          en <a href={"https://www.facebook.com/nederlandimpact"}>Facebook</a>{" "}
          om op de hoogte te blijven.
        </p>
      </div>
    </div>
  );
};

export default WhatIsImpact;
