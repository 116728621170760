import Logo from "./assets/logo.svg";
import styles from "./Stichting.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const Stichting = () => {
  return (
    <div className={cx("stichting")}>
      <nav>
        <a href="/">
          <img className={cx("logo")} src={Logo} />
        </a>
      </nav>
      <section>
        <h1>Over Stichting IMPACT Nederland</h1>
        <p>
          In deze sectie vindt u algemene gegevens van Stichting IMPACT
          Nederland. De stichting is opgericht op 12 april 2024.
        </p>

        <table>
          <tr>
            <th>Statutaire naam:</th>
            <td>Stichting IMPACT Nederland</td>
          </tr>
          <tr>
            <th>KvK-nummer:</th>
            <td>93593627</td>
          </tr>
          <tr>
            <th>RSIN:</th>
            <td>866461632</td>
          </tr>
          <tr>
            <th>Post&shy;adres:</th>
            <td>Arendschelling 75, 8253CC Dronten</td>
          </tr>
          <tr>
            <th>Telefoon&shy;nummer:</th>
            <td>
              <a href="tel:0031681824960">+31 6 81 82 49 60</a>
            </td>
          </tr>
          <tr>
            <th>E-mail&shy;adres:</th>
            <td>
              <a href="mailto:nederland@impactgeneration.org">
                nederland@&shy;impactgeneration.org
              </a>
            </td>
          </tr>
        </table>
        <h2>Doel&shy;stelling</h2>
        <p className={cx("acronym")}>
          {/* <strong> semantically has a different meaning */}
          Het doel van stichting IMPACT Nederland wordt duidelijk uit de naam.{" "}
          <strong>IMPACT</strong> staat voor <span>I</span>nspiring{" "}
          <span>M</span>embers to <span>P</span>roclaim the <span>A</span>dvent
          of <span>C</span>hrist <span>T</span>ogether: het inspireren van
          (kerk/gemeente)leden om de (weder)komst van Christus samen te
          verkondigen. Dit wordt gedaan door het karakter van Jezus Christus te
          weerspiegelen aan de wereld doormiddel van liefdadigheidswerk en
          evangelisatie.
        </p>
        <p>
          Een belangrijk onderdeel van IMPACT is de
          Zevendedags&shy;adventisten&shy;gemeente waarmee wordt samengewerkt.
          De gemeente wordt ondersteund in hun evangelisatie&shy;activiteiten om
          zo hun lokale invloed op een positieve manier uit te breiden. De
          gemeenteleden nemen deel en worden zo geïnspireerd om deze
          activiteiten voort te zetten, ook nadat IMPACT voorbij is.
        </p>
        <p>
          Samengevat is het doel van IMPACT Nederland het maken van een
          positieve IMPACT op de omgeving waar door IMPACT georganiseerde
          evenementen plaatsvinden.
        </p>
        <h2>Hoofdlijnen van het beleidsplan</h2>
        <p>
          In het beleidsplan staat het verleden, heden en de toekomst van
          stichting IMPACT Nederland beschreven. Ook worden de financiële
          werkwijze en doel&shy;stellingen behandeld, evenals de organisatie en
          haar activiteiten.
          <p />
          <p>
            IMPACT Nederland is begonnen in 2019, nadat enkele
            vrij&shy;willigers een IMPACT camp&shy;meeting hebben meegemaakt in
            Oostenrijk. Het organiseren van IMPACT Nederland begon, en in 2021
            werd de eerste IMPACT Nederland camp&shy;meeting gehouden.
          </p>
          <p>
            Een van de grootste inkomsten voor IMPACT Nederland zijn de
            deelnemers&shy;kosten. De mensen die aan de campmeeting deelnemen
            betalen een bepaald bedrag om de kosten van o.a. hun verblijf en
            eten te dekken. Een andere belangrijke inkomstenbron zijn donaties.
            De sponsoren van IMPACT zorgen ervoor dat de campmeeting
            toegankelijk blijft voor iedereen die wil deelnemen.
          </p>
          <p>
            Om inkomsten te genereren worden er ook producten verkocht. Zo zijn
            er af en toe een paar 'Limited Edition' hoodies, t-shirts of ander
            promotiemateriaal te koop. De opbrengst van deze producten gaat naar
            het dekken van de kosten van de campmeeting.
          </p>
          <p>
            De grootste kostenpost van IMPACT is de accommodatie. Deelnemers
            moeten kunnen slapen, eten, workshops bijwonen en meer. Een goeie
            locatie is erg belangrijk voor het slagen van de campmeeting.
          </p>
          <p>
            Andere kosten zijn verblijf, eten, promotie, sprekers en
            vergoedingen. IMPACT Nederland streeft ernaar om de kosten zo laag
            mogelijk te houden.
          </p>
          <p>
            IMPACT Nederland streeft er niet naar vermogen op te bouwen. Als
            IMPACT Nederland een overschot aan geld heeft, wordt dit gebruikt om
            verdere kosten te dekken of om te investeren in de campmeeting.
          </p>
          Het volledige beleidsplan is op te vragen door een e-mail te sturen
          naar{" "}
          <a href="mailto:nederland@impactgeneration.org?subject=Aanvraag Beleidsplan Stichting IMPACT Nederland">
            nederland@&shy;impactgeneration.org
          </a>
          .
        </p>
        <h2>Bestuurs&shy;samen&shy;stelling</h2>
        <div className={cx("board")}>
          {BoardMembers.map((member) => (
            <div className={cx("member")}>
              <img src={`/${member.name}.webp`} />
              <h3>{member.name}</h3>
              <p>{member.role}</p>
            </div>
          ))}
        </div>
        <h2>Belonings&shy;beleid</h2>
        <p>
          Stichting IMPACT Nederland werkt met vrijwilligers. Er wordt geen
          beloning gegeven aan de bestuursleden. Gemaakte onkosten worden
          vergoed.
        </p>
        <h2>Financiële verantwoording</h2>
        <p>
          Stichting IMPACT Nederland is opgericht op 12 april 2024 en heeft nog
          geen financieel overzicht van dit jaar.
        </p>
      </section>
    </div>
  );
};

const BoardMembers = [
  {
    name: "Orville Vroemen",
    role: "Voorzitter",
  },
  {
    name: "Kelsey Gittens",
    role: "Secretaris",
  },
  {
    name: "Harry Vroemen",
    role: "Penningmeester",
  },
];

export default Stichting;
