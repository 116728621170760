import React from "react";
import classNames from "classnames/bind";
import styles from "./Contact.module.css";
import teamFoto from "./assets/team-foto.jpeg";

const cx = classNames.bind(styles);

const Contact = (props) => {
  return (
    <div id={"contact"} className={cx("base")}>
      <div className={cx("flex-columns")}>
        <div>
          <h1>Contact</h1>
          <p>Interested? We would love to hear from you!</p>

          <p>
            <a href="tel:0031636260011">+31 (0) 6 36 26 00 11</a>
          </p>
          <p>
            <a href="mailto:nederland@impactgeneration.org">
              nederland@impactgeneration.org
            </a>
          </p>
          <br />
          <br />
          <div
            class="gfm-embed"
            data-url="https://www.gofundme.com/f/ge9hxs-impact-nederland-support/widget/medium/"
          ></div>
        </div>
        <img src={teamFoto} alt="" />
      </div>
    </div>
  );
};

export default Contact;

// Add this GoFundMe script to the end of the body of the index.html of the build!
// <script src="/static/js/2.34e836b0.chunk.js"></script><script src="/static/js/main.8fb1649b.chunk.js"></script><script defer src="https://www.gofundme.com/static/js/embed.js"></script>
