import React from "react";
import classNames from "classnames/bind";
import styles from "./WhatIsImpact.module.css";
import heartbeat from "../../assets/heartbeat.png";

const cx = classNames.bind(styles);

const WhatIsImpact = (props) => {
  return (
    <div id={"impact"} className={cx("base")}>
      <div className={cx("container")}>
        <h1>What is Impact?</h1>
        <p>
          IMPACT is an acronym that stands for: “Inspiring Members to Proclaim
          the Advent of Christ Together.”
        </p>

        <img src={heartbeat} alt="" />

        <p>
          Impact is a youth-run organization that focuses on motivating youth
          and church members to take an active part in evangelism. This is
          primarily accomplished by organizing an annual mission trip in the
          Netherlands, where Impact works together with a local church. There
          are various outreach activities during this mission trip, as well as
          Bible study, workshops, games and socialization.
        </p>
        <p>
          This year will be the fourth time that Impact is organized in the
          Netherlands. Stay up to date by following us on{" "}
          <a href={"https://www.instagram.com/nederlandimpact/"}>Instagram</a>{" "}
          en <a href={"https://www.facebook.com/nederlandimpact"}>Facebook</a> .
        </p>
      </div>
    </div>
  );
};

export default WhatIsImpact;
