import React from "react";
import classNames from "classnames/bind";
import styles from "./Photos.module.css";

import photo1 from "./assets/photo3.JPG";
import photo2 from "./assets/photo20.JPG";
import photo3 from "./assets/photo5.JPG";
import photo4 from "./assets/photo4.JPG";
import photo5 from "./assets/photo12.JPG";
import photo6 from "./assets/photo17.JPG";
import photo7 from "./assets/photo7.JPG";
import photo8 from "./assets/photo8.JPG";
import photo9 from "./assets/photo1.JPG";
import photo10 from "./assets/photo10.JPG";
import photo11 from "./assets/photo11.JPG";
import photo12 from "./assets/photo24.JPG";
import photo13 from "./assets/photo13.JPG";
import photo14 from "./assets/photo14.JPG";
import photo15 from "./assets/photo25.JPG";
import photo16 from "./assets/photo16.JPG";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const cx = classNames.bind(styles);

const Photos = (props) => {
  const photos = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
    photo10,
    photo11,
    photo12,
    photo13,
    photo14,
    photo15,
    photo16,
  ];
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    pauseOnFocus: true,
    speed: 1000,
    easing: "ease-out",
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 680,
        settings: {
          autoplaySpeed: 2500,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div id={"about"} className={cx("base")}>
      <div className={cx("container")}>
        <section>
          <Slider {...settings}>
            {photos.map((photo) => (
              <div>
                <img src={photo} alt="" />
              </div>
            ))}
          </Slider>
        </section>
      </div>
    </div>
  );
};

export default Photos;
