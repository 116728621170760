import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./Home.css";

import NavBar from "./sections/NavBar/NavBar";
import HeroSection from "./sections/HeroSection/HeroSection";
import WhatIsImpact from "./sections/WhatIsImpact/WhatIsImpact";
import Contact from "./sections/Contact/Contact";
import About from "./sections/About/About";
import Photos from "./sections/Photos/Photos";

import NavBarEN from "./sections-en/NavBar/NavBar";
import HeroSectionEN from "./sections-en/HeroSection/HeroSection";
import WhatIsImpactEN from "./sections-en/WhatIsImpact/WhatIsImpact";
import ContactEN from "./sections-en/Contact/Contact";
import AboutEN from "./sections-en/About/About";
import PhotosEN from "./sections-en/Photos/Photos";

const cx = classNames.bind(styles);

const Home = () => {
  const [lang, setLang] = useState(navigator.language === "nl" ? "nl" : "en");
  return lang === "nl" ? (
    <div className="Home">
      <NavBar toggleLang={() => setLang("en")} />
      <HeroSection />

      {/* <div className="mt-8 py-24 bg-blue-900">
        <div className="container mx-auto flex gap-10 flex-col items-center md:flex-row">
          <img className="mx-auto max-w-lg" src="/united-prayer.png" alt="" />
          <div className="w-full text-white">
            <h2 className="text-white font-bold">
              United Prayer voor Impact 2024
            </h2>
            <h2 className="text-white font-bold">19:30-20:00</h2>
            <p className="text-white font-bold">
              Zonder gebed zijn onze beste pogingen nutteloos.
            </p>
            <p className="text-white">
              We zullen kort gezamelijk bidden voor Impact. Iedereen is welkom!
            </p>
            <a
              href="https://meet.google.com/bqo-rjzp-rrt"
              className="inline-flex gap-2 mt-6 bg-white hover:bg-blue-200 transition text-blue-600 font-bold rounded p-4"
            >
              {" "}
              Google Meet link{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div> */}

      <Photos />
      <About />
      <WhatIsImpact />

      <Contact />
    </div>
  ) : (
    <div className="Home">
      <NavBarEN toggleLang={() => setLang("nl")} />
      <HeroSectionEN />

      {/* <div className="mt-8 py-24 bg-blue-900">
        <div className="container mx-auto flex gap-10 flex-col items-center md:flex-row">
          <img className="mx-auto max-w-lg" src="/united-prayer.png" alt="" />
          <div className="w-full text-white">
            <h2 className="text-white font-bold">
              United Prayer for Impact 2024
            </h2>
            <h2 className="text-white font-bold">19:30-20:00</h2>
            <p className="text-white font-bold">
              Without prayer, all out efforts are useless.
            </p>
            <p className="text-white">
              We will pray together for Impact. Everyone is welcome!
            </p>
            <a
              href="https://meet.google.com/bqo-rjzp-rrt"
              className="inline-flex gap-2 mt-6 bg-white hover:bg-blue-200 transition text-blue-600 font-bold rounded p-4"
            >
              {" "}
              Google Meet link{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                class="size-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div> */}

      <PhotosEN />
      <AboutEN />
      <WhatIsImpactEN />

      <ContactEN />
    </div>
  );
};

export default Home;
