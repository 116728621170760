import React from "react";
import classNames from "classnames/bind";
import styles from "./About.module.css";
import photo1 from "./assets/impact-groepsfoto.jpg";
import photo2 from "./assets/group.jpg";
import photo3 from "./assets/instruments.jpg";
import photo4 from "./assets/outreach.jpg";
import photo5 from "./assets/outreach-old.jpg";
import tijdschema from "./assets/programma.png";
import { Create } from "@material-ui/icons";

const cx = classNames.bind(styles);

const About = (props) => {
  return (
    <div id={"about"} className={cx("base")}>
      <div className={cx("container")}>
        <section>
          <div className={cx("news-item")}>
            {/* <img src={photo2} alt="" /> */}
            <img src={photo1} alt="" />
            {/* <img src={photo3} alt="" /> */}

            {/* <img src={photo5} alt="" /> */}
            {/* <img src={photo4} alt="" /> */}
            <div>
              <h3>July 30-August 4, 2024</h3>
              <h4>Heerlen</h4>

              <h4>Main speaker:</h4>
              <em>Melvin Sandelin</em>
              <h4>Interested?</h4>
              <small>
                Registration starts on February 26<sup>th</sup>
              </small>
              {/* <small>
                Meld je aan vóór <strong>3 juli</strong>
              </small> */}
              <button
                type="submit"
                onClick={() =>
                  (window.location =
                    "https://registerseat.com/at/impact-nl-2024")
                }
              >
                Register <Create />
              </button>
            </div>
          </div>

          <div className={cx("text")}>
            <h1>IMPACT Netherlands 2024</h1>
            <p>
              In 2024, IMPACT Nederland will be organized again from July 30 to
              August 4. In collaboration with the Adventist church in Heerlen, a
              group of young people will use creative ways to bring people in
              Heerlen into contact with the good news about Jesus.
            </p>

            <h3>Frequently Asked Questions</h3>

            <h4>How many participants will there be?</h4>
            <p>We expect to have approximately 50-60 participants.</p>

            <h4>What ages is IMPACT intended for?</h4>
            <p>
              People between the ages of 18 and 40 are our main target group.
              Yet we do not want to exclude anyone. If you are young at heart,
              you are also welcome.
            </p>

            <h4>What will the program look like?</h4>
            <p>
              Every year, the program is slightly different. But, as an example,
              here's an overview of the program from a previous year:{" "}
              <a href={tijdschema}>
                <strong>Program 2024</strong>
              </a>
            </p>

            <h4>What does it cost to participate?</h4>
            {/* <p>
              This year's prices are still being calculated. Follow us on social
              media to be informed as soon as the prices are announced.
            </p> */}

            <table>
              <tr>
                <th colSpan="2">
                  <strong>Registration, accommodation and meals</strong>
                </th>
              </tr>
              <tr>
                <th colSpan="2"> </th>
              </tr>
              <tr>
                <th>
                  <strong>Students</strong>
                </th>
                <td>€154,-</td>
              </tr>
              <tr>
                <th>
                  <strong>Young professionals</strong>
                </th>
                <td>€179,-</td>
              </tr>
            </table>

            <h4>What arrangements are there for accommodation?</h4>
            <p>
              Group accommodation in Heerlen will be arranged for all
              participants. We won't be camping this year (unless you really
              want to – let us know)!🙌 More specific details will follow.
            </p>
            {/* <h4>Hoe zit het met accomodatie?</h4>
            <p>
              Dit jaar zijn er drie accomodatie opties op de camping waar we
              zullen slapen.
            </p>
            <ul>
              <li>
                Op het Vakantiepark Koningshof kamperen in een{" "}
                <strong>zelf meegebrachte tent</strong> (64 euro pp voor 5
                nachten)
              </li>
              <li>
                Op het Vakantiepark Koningshof in een{" "}
                <strong>Premium Lodge</strong> (230 euro pp voor 5 nachten).{" "}
                <br /> <strong>Let op!</strong> Er is een beperkte aantal
                slaapplekken in de Premium Lodges. Dus{" "}
                <strong>meld je zo snel mogelijk aan</strong> als je in een
                chalet wilt slapen (of neem zsm contact met ons op)!
              </li>
              <li>
                Daarnaast is het mogelijk om voor eigen accommodatie te zorgen.
              </li>
            </ul>

            <h4>Prijzen overzicht:</h4>
            <table>
              <tr>
                <th>
                  <strong>Plekje voor je (eigen) tent</strong>
                </th>
                <td>€64,-</td>
              </tr>
              <tr>
                <th>
                  <strong>Chalet ('Premium Lodge')</strong>
                </th>
                <td>€230,-</td>
              </tr>
            </table> */}

            {/* <h4>Tot wanneer kan ik me nog aanmelden?</h4>
            <p>
              Aanmelding is nog tot 9 juli mogelijk, maar als je in een chalet
              wilt slapen raden we aan om je zo snel mogelijk aan te melden om
              je plek in een chalet te bevestigen.
            </p> */}

            <h4>Am I required to participate in all parts of the program?</h4>
            <p>
              No program component is absolutely mandatory. But we encourage
              participating as much as possible, as that is the way to get the
              most out of your Impact experience.
            </p>

            <h4>Is the food at IMPACT vegetarian/vegan?</h4>
            <p>
              Yes, the food will be completely vegetarian; and there will always
              be vegan options!
            </p>

            <h4>Who is the main speaker?</h4>
            <p>
              This year we'll have Melvin and Katja Sandelin joining us during
              IMPACT!
            </p>
            <p>
              Melvin, Katja and their son Elionai have a powerful Youtube
              ministry with over 130K subscribers and millions of views in
              total. Through their channel and their Kindness movement app, they
              seek to share with people, in our secular, European culture, what
              a life with Jesus can be like.
            </p>
            <p>
              They will help us see how powerfully God can use us to reach
              people, even in our secular culture.
            </p>

            <h4>If I donate, where does the money go?</h4>
            <p>
              The donations we receive ensure that participant costs remain low
              while still allowing us to cover all our costs. Donations are
              spent on food, accommodation and evangelism material (books,
              stands in the center of Lelystad, Heatlh Expo, etc.), among other
              things.{" "}
              <a href="mailto:nederland@impactgeneration.org">Email us</a> for a
              detailed overview of the IMPACT Netherlands 2024 budget.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
